@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic&family=Poppins:wght@400;500;600;700&display=swap');

.custom-navbar {
  background-color: #fff;
  z-index: 1000;
  position: fixed;
  top: 0;
  
}

/* Additional styles for RTL layout */
.custom-navbar.rtl {
  direction: rtl;
}

/* Additional styles for LTR layout */
.custom-navbar.ltr {
  direction: ltr;
}


/* Default LTR styles */
.custom-navbar.ltr .nav-link {
  font-family: 'Poppins', sans-serif;
}

.custom-navbar.rtl .nav-link {
  font-family: 'Noto Kufi Arabic' !important;
}

.logo {
  height: 68px;
  width: auto;
  padding: 4px;
  cursor: pointer;
}

.navbar-expand-lg {
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-top: 43px;
  padding-bottom: 10px;
  background: transparent;
  box-shadow: 0 0 10px rgba(96, 0, 210, 0.5);
   
}


.nav-link {
  color: black !important;
  text-align: center;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
  letter-spacing: 0.5px;
}

.nav-link:hover {
  color: #fea701 !important;
}

.nav-link.active {
  color: #fea701 !important;
  border-bottom: solid 1px #262d79;
}

/* Hide the default dropdown icon */
.custom-dropdown .dropdown-toggle::after {
  display: none;
}

/* Custom dropdown icon rotation */
.dropdown-icon {
  transition: transform 0.3s ease;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.custom-dropdown .dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.custom-dropdown:hover .dropdown-menu,
.custom-dropdown .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}



.toggle-icon {
  color: #262d79;
  font-size: 1.5rem;
}

.language-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
}

.toggle-switch.english {
  background-color: #410087;
}

.toggle-switch.arabic {
  background-color: #F5F7F8;
  color: #1E201E;
}

.toggle-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  color: #1E201E;
  border-radius: 25px;
  transition: left 0.3s ease;
  font-size: 0.75rem; /* Adjust font size here */
  font-weight: bold; /* Ensure font is bold */
}

.toggle-switch.english .toggle-thumb {
  left: 0;
}

.toggle-switch.arabic .toggle-thumb {
  left: 50%;
  background-color: #410087;
  color: #fff;
}

.language-label {
  color: #231f20;
}

/* Responsive Styles */
@media (max-width: 991px) {
  .menu-items {
    text-align: center;
    width: 100%;
  }

  .nav-link {
    margin: 0.5rem 0;
  }

  
}

@media (max-width: 768px) {
  .toggle-switch {
    width: 40px;
    height: 20px;
  }

  
}


@media (max-width: 480px){
.navbar-expand-lg{
  padding-bottom: 0px !important;
}
}
