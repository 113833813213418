.floating-phone-call {
    position: fixed;
    top: 8em;
    right: 2em;
    background-color: #256ad3;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 100;
  }
  
  .floating-phone-call:hover {
    background-color: #128C7E;
  }
  
  .floating-phone-call::before,
  .floating-phone-call::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: rgba(37, 106, 211, 0.5); /* Same green with transparency */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: wave-animation 3s infinite ease-in-out;
    z-index: -1;
  }
  
  .floating-phone-call::after {
    animation-delay: 1.5s;
  }
  
  @keyframes wave-animation {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }

  @media (max-width: 480px){
    .floating-phone-call{
      height: 45px !important;
    width: 45px !important;
    top: 10em;
    }
  }
  