@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic&family=Poppins:wght@400;500;600;700&display=swap');


.btn-style{
    border: none ;
      border-radius: 30px ;
      font-size: 1.2rem ;
      padding: 0.8rem 2rem ;
      transition: all 0.3s ease-in-out ;
      display: inline-block;
      cursor: pointer;
      background: #6809cc;
  color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}



.btn-style:hover{
  transform: translateY(-3px) scale(1.05);
  background: #fea701;
  color: black;
}



.heading{
  font-family: "Poppins", sans-serif !important;
  font-size: 3rem;
}


.sub-heading{
  font-family: "Poppins", sans-serif !important;
  font-size: 1.5rem;
}


.paragraph{
  font-family: "Poppins", sans-serif ;
  font-size: 17px ;
  line-height: 1.9;
}



.navbarPositions{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
}

.floating-whatsapp-button {
  position: fixed;
  width: 60px; /* Explicit size */
  height: 60px;
  bottom: 20px;
  right: 20px;
  transition: opacity 0.3s ease-in-out;
}

.floating-trading-view {
  position: fixed;
  -webkit-user-select: none; /* For Safari and older browsers */
  user-select: none;          /* For modern browsers */
  box-sizing: border-box;
  display: block;
  height: 50px;
  width: 100%;
  bottom: 0px;
  z-index: 100;
}






.arabic-heading{
  font-family: 'Noto Kufi Arabic' ;
  font-size: 3rem;
}

.arabic-sub-heading{
  font-family: 'Noto Kufi Arabic' ;
  font-size: 1.5rem;
}


.arabic-paragraph{
  font-family: 'Noto Kufi Arabic' ;
  font-size: 17px ;
  line-height: 1.9;
}

@media(max-width: 1024px){
  .heading {
    font-size: 2rem; 
  }

  .sub-heading{
    font-size: 1.2rem;
  }

  .arabic-heading{
    font-size: 2rem;
  }
  
  .arabic-sub-heading{
    font-size: 1.2rem;
  }

}

@media (max-width: 991px) {

  .heading {
    font-size: 1.8rem; 
  }

  .arabic-heading{
    font-size: 1.8rem; 
  }

  

  .btn-style {
    font-size: 0.9rem; 
    padding: 0.6rem 1.5rem; 
  }
}



@media (max-width: 480px) {

  .navbarPositions{
    margin-top: -3em !important;
  }

  .heading {
    font-size: 1.5rem; 
  }

  .btn-style {
    font-size: 0.8rem; 
    padding: 0.5rem 1.2rem; 
    border-radius: 20px; 
  }

  .floating-whatsapp-button{
    height: 45px !important;
    width: 45px !important;
  }
}



    